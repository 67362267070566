import { down, up } from 'styled-breakpoints';
import styled from 'styled-components';
export var PostTitle = styled.p.withConfig({
  displayName: "styles__PostTitle",
  componentId: "sc-97bamo-0"
})(["display:flex;flex-direction:column;flex:1;font-weight:normal;color:", " !important;margin-bottom:8px;", "{font-size:20px !important;}"], function (props) {
  return props.theme.colors.undp.green;
}, down('md'));
export var PostContainer = styled.div.withConfig({
  displayName: "styles__PostContainer",
  componentId: "sc-97bamo-1"
})(["display:flex;flex-direction:column;width:100%;", "{&:not(:first-of-type){margin-top:12px;}}&:hover{img{margin-left:0px !important;}}section{display:flex;flex-direction:column;background-color:#fff;padding:20px 24px;height:190px;time{display:flex;font-size:14px;color:#9b9fa4;margin-bottom:8px;}a{display:flex;flex-direction:column;align-items:flex-start;transition:all 0.2s;flex:1;&:hover{img{opacity:0.7;}}}", "{height:max-content;}}"], down('md'), down('md'));
export var Cover = styled.div.withConfig({
  displayName: "styles__Cover",
  componentId: "sc-97bamo-2"
})(["position:relative;display:flex;justify-content:center;align-items:center;flex:1 1;> img{position:absolute;height:100%;width:100%;object-fit:cover;cursor:pointer;}", "{height:180px;min-height:180px;}"], down('md'));
export var PlayVideoContainer = styled.div.withConfig({
  displayName: "styles__PlayVideoContainer",
  componentId: "sc-97bamo-3"
})(["display:flex;height:max-content;padding:15px 22px;border:1px solid #ffffff;border-radius:50px;background-color:rgba(0,0,0,0.35);transition:all 0.3s;&:hover{cursor:pointer;background-color:rgba(0,0,0,0.8);a{color:", ";}}a{display:flex;font-size:18px;line-height:100%;color:#ffffff;span{margin-left:12px;}}z-index:20;"], function (props) {
  return props.theme.colors.undp.green;
});
export var Container = styled.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-97bamo-4"
})(["display:flex;flex-direction:column;> p{display:flex;justify-content:center;font-size:14px;color:#9b9fa4;font-weight:600;text-transform:uppercase;letter-spacing:0.25px;}> h2{display:flex;justify-content:center;text-align:center;font-size:36px;color:#11151a;font-weight:700;margin:40px 0;", "{text-align:center;font-size:27px;}}"], down('md'));
export var Posts = styled.div.withConfig({
  displayName: "styles__Posts",
  componentId: "sc-97bamo-5"
})(["display:grid;flex-direction:column;grid-template-columns:auto 420px;grid-template-rows:220px 410px;column-gap:16px;row-gap:16px;", "{display:flex;flex-direction:column;}", ":nth-child(1){grid-column-start:1;grid-column-end:2;grid-row-start:1;grid-row-end:3;", "{font-size:30px;", "{overflow:hidden;line-clamp:2;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;text-overflow:ellipsis;line-height:150%;max-height:100px;}}}", ":nth-child(2){grid-column-start:2;grid-column-end:3;grid-row-start:1;grid-row-end:2;", "{flex-direction:row-reverse;section{height:100%;width:210px;}}", "{font-size:22px;", "{overflow:hidden;line-clamp:4;display:-webkit-box;-webkit-line-clamp:4;-webkit-box-orient:vertical;text-overflow:ellipsis;line-height:150%;max-height:150px;}}}", ":nth-child(3){grid-column-start:2;grid-column-end:3;grid-row-start:2;grid-row-end:3;", "{font-size:22px;", "{overflow:hidden;line-clamp:3;display:-webkit-box;-webkit-line-clamp:3;-webkit-box-orient:vertical;text-overflow:ellipsis;line-height:140%;max-height:100px;}}}"], down('md'), PostContainer, PostTitle, up('lg'), PostContainer, up('lg'), PostTitle, up('lg'), PostContainer, PostTitle, up('lg'));
export var BlogPreviewWrapper = styled.div.withConfig({
  displayName: "styles__BlogPreviewWrapper",
  componentId: "sc-97bamo-6"
})(["background-color:#f3f4f8;padding:72px 0;", "{padding:32px 0;}"], down('md'));